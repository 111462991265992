import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 16px;
`;

interface RadioButtonLabelProps {
	isSelected?: boolean;
	isExpandable?: boolean;
}

const RadioButtonLabel = styled.div<RadioButtonLabelProps>`
	border: black 1px solid;
	padding: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 12px; // was 8px
	cursor: pointer;

	@media ${(props) => props.theme.screens.medium} {
		padding: 24px;
		gap: 16px;
	}

	background: ${(props) => props.isSelected && !props.isExpandable && props.theme.colors.accentLight};
	border: 2px solid black;
	border-color: ${(props) => (props.isSelected ? props.theme.colors.accent : 'rgba(0,0,0,0.1)')};
	border-radius: 10px;

	${(props) =>
		props.isSelected &&
		props.isExpandable &&
		`
		border-bottom-left-radius: unset;
		border-bottom-right-radius: unset;
		border-bottom: 2px solid rgba(0,0,0,0.1);
  	`}

	// icon
	.selected {
		color: ${(props) => props.theme.colors.accent};
	}
`;

const RadioButtonLabelText = styled.div`
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		h3 {
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 0px;
		}
		p {
			margin-top: 6px;
			margin-bottom: 0; // reset fix
		}
	}
`;

const DateAndTimeSlotText = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 6px;
	gap: 2px;

	@media ${(props) => props.theme.screens.medium} {
		flex-direction: row;
		align-items: center;
		gap: 16px;
	}
`;

interface TimeSlotRadioButtonLabelProps {
	isSelected?: boolean;
}

const TimeSlotRadioButtonLabel = styled.label<TimeSlotRadioButtonLabelProps>`
	&&& {
		padding: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		background: ${(props) => (props.isSelected ? props.theme.colors.accentLight : 'transparent')};
		border: 2px solid black;
		border-color: ${(props) => (props.isSelected ? props.theme.colors.accent : 'transparent')};
		border-radius: 5px;
		border: 1px solid rgba(0, 0, 0, 0.1);

		// time interval
		span {
			flex-grow: 1;
		}

		input {
			width: unset;
		}
	}
`;

const DateAndTimeSlotContainer = styled.div`
	// added to make content vertically scrollable
	display: flex;
	flex-direction: column;
	overflow: hidden;
`;

const TimeSlotContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden; // added to make the drawer content scrollable
`;

const AvailableTimeSlotsText = styled.div`
	font-weight: bold;
	padding-top: 20px;
	padding-bottom: 20px;

	@media ${(props) => props.theme.screens.medium} {
		padding-bottom: 0;
		margin-top: 20px;
		margin-bottom: 20px;
	}
`;

const ScrollableContentWrapper = styled.div`
	overflow-y: auto;
	touch-action: pan-y;
	// note: max-height isn't used here so the height is the same for different days with a different number of time slots
	// note: should be smaller than max-height at DrawerContent
	height: 40vh;
`;

const DateAndTimeSlotContainerDesktop = styled.div`
	border: 2px solid ${(props) => props.theme.colors.accent};
	border-top: none;
	border-radius: 10px;
	border-top-left-radius: unset;
	border-top-right-radius: unset;
	margin-top: -8px;
	padding-left: 20px;
	padding-right: 20px;

	// Initial state of entering and exiting elements
	&.DateAndTimeSlotContainerDesktop-enter,
	&.DateAndTimeSlotContainerDesktop-exit-active {
		opacity: 0;
		max-height: 0;
		overflow: hidden; // Ensure content outside of max-height is not visible
		transition: opacity 300ms, max-height 300ms ease-out;
	}

	// State of elements that have completed the entering phase or started the exiting phase
	&.DateAndTimeSlotContainerDesktop-enter-active,
	&.DateAndTimeSlotContainerDesktop-exit {
		opacity: 1;
		max-height: 1000px; // Adjust to the maximum height your content might have
		overflow: hidden;
		transition: opacity 300ms, max-height 300ms ease-in;
	}
`;

const TimeSlotList = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0.5rem;
`;

const TimeSlot = styled.div`
	padding: 0.5rem;
	margin: 0.5rem 0;
	border: 1px solid #ccc;
	border-radius: 5px;
`;

const ConfirmButton = styled.div`
	display: flex;
	margin-top: 5rem;
	button {
		flex: 1 !important;
		justify-content: center !important;
	}
	@media ${(props) => props.theme.screens.medium} {
		justify-content: flex-start !important;
		button {
			flex: 0 0 auto !important;
		}
	}
`;

const S = {
	Container,
	RadioButtonLabel,
	RadioButtonLabelText,
	TimeSlotRadioButtonLabel,
	TimeSlotContainer,
	AvailableTimeSlotsText,
	ScrollableContentWrapper,
	TimeSlotList,
	TimeSlot,
	DateAndTimeSlotContainer,
	DateAndTimeSlotContainerDesktop,
	ConfirmButton,
	DateAndTimeSlotText
};

export default S;
