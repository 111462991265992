import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput, SessionInput } from './BaseInput';
import { User } from '../../models';

export type GetSessionSummaryInput = SessionInput &
	LanguageInput & {
		user?: User;
		pdfType: 'Default' | 'Callback';
		callbackTime?: Date;
	};

async function CallGetSessionSummary(apiKey: string, input: GetSessionSummaryInput): Promise<Blob | undefined> {
	try {
		const { data } = await minddApiAxios.post<Blob>('GetSessionSummary?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			},
			responseType: 'blob'
		});

		return data;
	} catch (error) {
		logAxiosError(error, 'CallGetSessionSummary');
	}

	return undefined;
}

export { CallGetSessionSummary };
