import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput } from './BaseInput';

export type GetChannelSettingsInput = LanguageInput;

export type GetChannelSettingsContactRequest = {
	hasCallbackRequestTypeWithTimeSchedule: boolean;
	includePdf: boolean;
	clientNameRequired: boolean;
	clientExtensiveNameRequired: boolean;
	clientDateOfBirthRequired: boolean;
	clientGenderRequired: boolean;
	clientPhonenumberRequired: boolean;
	active: boolean;
	clientAddressRequired: boolean;
	clientHouseNumberRequired: boolean;
	clientZipCodeRequired: boolean;
	clientBsnNumberRequired: boolean;
	clientBsnNumberOptional: boolean;
	clientEmailRequired: boolean;
	clientEmailOptional: boolean;
	clientEmailOrBsnNumberRequired: boolean;
	clientCommentOptional: boolean;
	clientCommentRequired: boolean;
	clientPhotoOptional: boolean;
	clientDoctorRequired: boolean;
	clientDoctorOptional: boolean;
	clientHapLocationRequired: boolean;
	clientPhonenumberRegion: 'Netherlands' | 'Europe' | 'WorldWide';
	labels: {
		consent?: string;
		intro?: string;
		introWithoutConsultationPreparation?: string;
		introForZorgMail?: string;
		introError?: string;
		formLabelComment?: string;
		receipt?: string;
		sendButton?: string;
		successUrgency1?: string;
		successUrgency2?: string;
		successUrgency3?: string;
		successUrgency4?: string;
		successUrgency5?: string;
	};
	hapLocations: { title?: string }[];
	locationsForCallbackConfigs: { title: string }[];
};

export type GetChannelSettingsContactOptions = {
	labels: {
		callbackRequestButton?: string;
		callbackRequestIntro?: string;
		directCallButtonText?: string;
		directCallOptionIntro?: string;
		zorgMailRequestButton?: string;
		zorgMailRequestIntro?: string;
	};
};

export type GetChannelSettingsSurvey = {
	active: boolean;
	surveyId: number;
	labels: {
		intro: string;
		formIntro: string;
		formConsent: string;
		formSuccess: string;
		formError: string;
		success: string;
	};
};

export type GetChannelSettingsOutput = {
	title: string;
	contactOptions: GetChannelSettingsContactOptions;
	contactRequest: GetChannelSettingsContactRequest;
	emailAddress: string;
	clientName?: string;
	type?: 'Huisarts' | 'HAP' | 'Overig';
	city?: string;
	phoneNumber?: string;
	logoUrl?: string;
	useLanguageSelector: boolean;
	showFeedback: boolean;
	askForBirthday: boolean;
	maxAge: number;
	callButtonActive: boolean;
	askForZipCode: boolean;
	askForzipCodeIncludeBirthdate: boolean;
	askForGPPost: boolean;
	askForGPPostShowSkipButton: boolean;
	clientColor?: string;
	conditionsAndTermsText?: string;
	redirectToDoctorPageActive: boolean;
	redirectToDoctorPageUrl?: string;
	survey: GetChannelSettingsSurvey;
	welcomeText?: string;
	questionWhatGender?: string;
	brandingName?: string;
};

async function CallGetChannelSettings(apiKey: string, input: GetChannelSettingsInput): Promise<GetChannelSettingsOutput | undefined> {
	try {
		const { data } = await minddApiAxios.get<GetChannelSettingsOutput>(`GetChannelSettings?api-version=2.1&languageCode=${input.languageCode}`, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return data;
	} catch (error) {
		logAxiosError(error, 'CallGetChannelSettings');
	}

	return undefined;
}

export { CallGetChannelSettings };
